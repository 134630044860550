import { useAuth0 } from '@auth0/auth0-react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { OperatorInfo } from '#/hooks';
import { useAuth } from '#/hooks/useAuth';
import accountKeyStore from '#/store/AccountKeyStore';
import { AffiliateUser, AgentUser, OperatorUser } from '#/types/OBEUser';
import { JucyCountryCode } from '@jucy/rentals-common';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Logout from '@mui/icons-material/Logout';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
    Autocomplete,
    autocompleteClasses,
    AutocompleteProps,
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    Paper,
    Stack,
    styled,
    SxProps,
    TextField,
    Theme,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
} from '@mui/material';

const PartnerAccountSummaryPanel: React.FC<{ user: AgentUser; sx?: SxProps<Theme>; align?: 'right' | 'left' }> = ({ sx, user, align = 'right' }) => (
    <Box sx={sx} title={user.email}>
        <Typography component="div" variant="subtitle1" textAlign={align} sx={{ lineHeight: 1 }} gutterBottom={false}>
            {user.name}
        </Typography>
        <Typography component="div" variant="caption" textAlign={align} gutterBottom={false}>
            {user.parent}
        </Typography>
        {user?.branch !== user.parent && user?.branch !== user.name && user?.branch ? (
            <Typography component="div" variant="subtitle2" lineHeight={1} textAlign={align} gutterBottom={false}>
                {user.branch}
            </Typography>
        ) : null}
    </Box>
);

const AffiliateAccountSummaryPanel: React.FC<{ user: AffiliateUser; sx?: SxProps<Theme>; align?: 'right' | 'left' }> = ({ sx, user, align = 'right' }) => (
    <Box sx={sx} title={user.email}>
        <Typography component="div" variant="subtitle1" textAlign={align} sx={{ lineHeight: 1 }} gutterBottom={false}>
            {user.nickname}
        </Typography>
        <Typography component="div" variant="caption" textAlign={align} gutterBottom={false}>
            {user.email}
        </Typography>
    </Box>
);

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: theme.palette.error.main,
        borderColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
        borderStyle: 'solid',
        borderWidth: 1,
        p: 1,
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
    },
}));

const OperatorAccountSummaryPanel: React.FC<{ user: OperatorUser; sx?: SxProps<Theme> }> = ({ user, sx }) => {
    const operatorWarnings: string[] = [];
    Object.values(JucyCountryCode).forEach((countryCode) => {
        if (!user?.rcmEmail[countryCode]) {
            operatorWarnings.push(`Missing RCM email for ${countryCode}`);
        }
    });
    if (operatorWarnings.length > 0) {
        return (
            <Stack sx={sx}>
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography
                                color="inherit"
                                sx={{
                                    fontSize: '1rem',
                                    lineHeight: '1.5',
                                    letterSpacing: '0.00938em',
                                    fontWeight: '500',
                                    mb: 0.5,
                                }}
                            >
                                Account is not set up
                            </Typography>
                            {operatorWarnings.map((warning) => (
                                <b key={warning}>
                                    {warning}
                                    <br />
                                </b>
                            ))}
                        </React.Fragment>
                    }
                >
                    <Paper
                        elevation={0}
                        sx={(t) => ({
                            background: t.palette.error.main,
                            borderColor: t.palette.error.dark,
                            color: t.palette.error.contrastText,
                            borderStyle: 'solid',
                            borderWidth: 1,
                            p: 1,
                        })}
                    >
                        <Stack direction="row" gap={2}>
                            <WarningAmberIcon />
                            <OperatorPanel user={user} />
                        </Stack>
                    </Paper>
                </HtmlTooltip>
            </Stack>
        );
    }
    return <OperatorPanel user={user} sx={sx} />;
};

const OperatorPanel: React.FC<{ user: OperatorUser; sx?: SxProps<Theme> }> = ({ user, sx }) => (
    <Stack direction="row" alignItems="center">
        <Box sx={sx}>
            <Typography component="div" variant="subtitle1" textAlign="right" sx={{ lineHeight: 1 }} gutterBottom={false}>
                {user.firstName} {user.lastName}
            </Typography>
            <Typography component="div" variant="caption" textAlign="right" gutterBottom={false}>
                {user.email}
            </Typography>
            {user.agentUser?.name ? (
                <Typography component="div" variant="subtitle2" lineHeight={1} textAlign="right" gutterBottom={false}>
                    {user.agentUser?.name}
                </Typography>
            ) : null}
        </Box>
        <PartnerImpersonationMenu user={user} />
    </Stack>
);
export const AccountSummary: React.FC<{ sx?: SxProps<Theme> }> = observer(({ sx }) => {
    const { user } = useAuth();
    if (user?.type === 'staff') {
        return <OperatorAccountSummaryPanel user={user} sx={sx} />;
    }
    if (user?.type === 'agent') {
        return <PartnerAccountSummaryPanel user={user} sx={sx} />;
    }
    if (user?.type === 'affiliate') {
        return <AffiliateAccountSummaryPanel user={user} sx={sx} />;
    }
    return null;
});

const PartnerImpersonationMenu: React.FC<{ user: OperatorUser }> = ({ user }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const auth0 = useAuth0();
    const open = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handlePartnerAccountSignOut = () => {
        handleClose();
        setTimeout(() => {
            if (auth0.isAuthenticated) {
                auth0.logout();
            }
            accountKeyStore.setImpersonation(undefined);
        }, 300);
    };
    const handleImpersonatePartner: AutocompleteProps<OperatorInfo['availableAccounts'][0], false, false, false>['onChange'] = (e, data) => {
        if (!data) {
            return;
        }
        handleClose();
        setTimeout(() => {
            if (data?.key) {
                accountKeyStore.setImpersonation(data.key);
            }
        }, 300);
    };
    const seenIds: string[] = [];
    const filteredAgents: { label: string; key: string }[] =
        user.availableAccounts
            ?.filter((acc) => {
                const isSeen = seenIds.includes(acc.key);
                if (!isSeen) {
                    seenIds.push(acc.key);
                    return true;
                }
                return false;
            })
            .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0)) || [];

    if (!user.availableAccounts?.length) {
        return null;
    }

    return (
        <Box>
            <IconButton onClick={handleOpen} size="small" sx={{ ml: 2 }} aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                autoFocus={false}
                disableAutoFocusItem={true}
                variant="menu"
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box component="li" sx={{ '&:hover, &.Mui-focusVisible': { background: 'initial' }, px: 2, py: 1.33 }}>
                    <Typography component="div" variant="subtitle2" sx={{ lineHeight: 1, mb: 1.5 }} gutterBottom={true}>
                        Sign in as partner account
                    </Typography>
                    <Autocomplete
                        size="small"
                        disablePortal
                        value={filteredAgents.find((a) => a.key === user.agentUser?.accountKey) || null}
                        isOptionEqualToValue={(option, value) => option.key === value.key}
                        getOptionLabel={(option) => `${option.label} ${option.key}`}
                        onChange={handleImpersonatePartner}
                        id="account-select"
                        options={filteredAgents}
                        sx={{
                            pointerEvents: 'auto',
                            width: 300,
                        }}
                        renderOption={(props, option) => <AccountLabel {...props} option={option} key={`${props.id}-${option.label}-${option.key}`} />}
                        renderInput={(params) => <TextField {...params} label="Account" />}
                        slotProps={{
                            listbox: {
                                sx: {
                                    [`.${autocompleteClasses.option}`]: {
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    },
                                },
                            },
                        }}
                    />
                </Box>
                {user.agentUser ? (
                    <Box component="li" onClick={handlePartnerAccountSignOut} sx={(theme) => ({ borderTop: `1px solid ${theme.palette.divider}`, px: 2, py: 1.33, display: 'flex' })}>
                        <ListItemText>
                            <PartnerAccountSummaryPanel align="left" user={user.agentUser} />
                        </ListItemText>
                        <ListItemIcon sx={{ flexDirection: 'column', alignItems: 'center' }}>
                            <Logout fontSize="small" />
                            Sign out
                        </ListItemIcon>
                    </Box>
                ) : null}
            </Menu>
        </Box>
    );
};

const AccountLabel: React.FC<React.HTMLAttributes<HTMLLIElement> & { option: { label: string; key: string } }> = ({ option, ...props }) => {
    const labelParts = option.label.split('|').map((s) => s.trim());
    const parent = labelParts[1];
    const branch = labelParts[2];
    const name = `${parent} - ${branch}` === labelParts[0] ? undefined : labelParts[0];
    const displayNameParts = [name, parent, branch].filter(Boolean);

    return (
        <Box
            {...props}
            component="li"
            title={`${displayNameParts.join(', ')} - ${option.key}`}
            sx={{
                lineHeight: 1.4,
                '& :nth-of-type(2)': {
                    pl: 1,
                    fontSize: '0.85rem',
                },
                '& :nth-of-type(3)': {
                    pl: 2,
                    fontSize: '0.75rem',
                },
            }}
        >
            {displayNameParts.map((name, i) => (
                <Box key={`${name}-${i}`}>{name}</Box>
            ))}
        </Box>
    );
};
