import { TripDetails, TripInfoRequest } from '@jucy/rentals-api-client/rentals-api-v3';
import { Site } from '../../lib/api/model/Site';
import { BookingCart } from '../../types/BookingCart';
import SitesStore from '../SitesStore';

export type SummaryFromTripDetailsProps = Partial<BookingCart> & { amendedTripDetails: TripDetails; tripInfo?: Partial<TripInfoRequest> };
export const summaryFromAmendmentDetails = ({ amendedTripDetails, tripInfo, ...props }: SummaryFromTripDetailsProps): BookingCart => {
    const selectedExcess = amendedTripDetails.lines?.find((line) => line.category === 'InsuranceFee' && !line.isExcluded);
    const mandatoryItems = amendedTripDetails.lines?.filter((line) => line.productId && line.isMandatory && !line.isExcluded).sort((p) => p.sortOrder || 0) || [];
    const addedProductIds = new Set([
        selectedExcess?.productId,
        ...mandatoryItems.map((line) => line.productId),
        ...(amendedTripDetails.bundles?.reduce((result, bundle) => [...result, ...bundle.bundledProducts.map((p) => p.productId)], [] as string[]) || []),
    ]);
    const selectedExtras = amendedTripDetails.lines?.filter((line) => !line.isMandatory && !addedProductIds.has(line.productId) && line.category === 'SecondaryProduct') || [];
    return new BookingCart({
        ...props,
        pickUpLocation: SitesStore.getSiteByCode(amendedTripDetails.pickUpLocation) as Site,
        dropOffLocation: SitesStore.getSiteByCode(amendedTripDetails.dropOffLocation) as Site,
        pickUpDate: amendedTripDetails.pickUpDate,
        dropOffDate: amendedTripDetails.dropOffDate,
        rentalDays: amendedTripDetails.rentalDays,
        driverAge: tripInfo?.driverAge,
        campaignCode: tripInfo?.campaignCode,
        fleetCategory: amendedTripDetails.fleetCategory,
        secondaryProducts: amendedTripDetails.secondaryProducts,
        insuranceProducts: amendedTripDetails.insuranceProducts,
        mandatoryItems: mandatoryItems,
        bundles: amendedTripDetails.bundles || [],
        selectedExcess,
        selectedExtras,
    });
};
