import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CardContent, CardMedia, DialogActions, DialogContent, DialogTitle, List, ListItem, Button as MuiButton, Card as MuiCard, Stack, Typography, Grid2 as Grid } from '@mui/material';
import { format, parseISO } from 'date-fns';
import Quote from '../../lib/api/model/Quote';
import { copyElementToClipboard } from '../../lib/copyElementToClipboard';
import CurrencyStore from '../../store/CurrencyStore';
import CurrencyFormatter from '../CurrencyFormatter/CurrencyFormatter';
import ModalDialog from '../ModalDialog/ModalDialog';
import CustomerReservationReviewCard from './CustomerReservationReviewCard';

const CustomerVoucherModal: React.FC<{ reservation: Quote }> = ({ reservation }) => {
    const [showCustomerModal, setShowCustomerModal] = useState(false);
    return reservation.isConfirmed ? (
        <>
            <MuiButton
                variant="text"
                onClick={() => setShowCustomerModal(true)}
                sx={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                }}
            >
                Show customer voucher <OpenInNewIcon sx={{ fontSize: '1.1em', ml: 0.5 }} />
            </MuiButton>
            <ModalDialog open={showCustomerModal} onClose={() => setShowCustomerModal(!showCustomerModal)} data-cy="dnr-modal" maxWidth="md">
                <DialogTitle>
                    <span className="h2 mb-0">Customer voucher {reservation.ReservationDisplayReference}</span>
                </DialogTitle>
                <DialogContent sx={{ paddingTop: 0, px: 1 }}>
                    <CustomerReservationReviewCard reservation={reservation} />
                </DialogContent>
                <DialogActions>
                    <MuiButton
                        variant="text"
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                        }}
                        onClick={() => {
                            copyElementToClipboard(document.getElementById('customer-voucher')).then((success) => {
                                if (success) {
                                    toast.success('Voucher copied');
                                } else {
                                    toast.error('Failed to copy voucher');
                                }
                            });
                        }}
                    >
                        Copy voucher to clipboard <ContentCopyIcon sx={{ fontSize: '1.1em', ml: 0.5 }} />
                    </MuiButton>
                </DialogActions>
            </ModalDialog>
        </>
    ) : null;
};

const ReservationReviewCard: React.FC<{ reservation: Quote }> = ({ reservation }) => {
    const extras = [
        ...reservation.MandatoryLines,
        ...(reservation.ExtrasLines || [])
            .slice()
            .filter((l) => !l.IsExcluded)
            .sort((p) => p.SortOrder),
    ];
    const selectedCurrencyCode = CurrencyStore.selectedCurrencyCode || reservation.TotalPrice.CurrencyCode;

    return (
        <>
            <MuiCard variant="outlined">
                <CardContent sx={{ maxWidth: '100%', '&:last-child': { pb: 2 } }}>
                    <Typography gutterBottom variant="h5" component="div" fontWeight={700}>
                        {reservation.FleetType.name} {reservation.isRequest ? 'request' : reservation.isQuote ? 'quote' : 'booking'}
                    </Typography>
                </CardContent>
                <CardContent sx={{ maxWidth: '100%', '&:last-child': { pb: 2 } }}>
                    <Grid container spacing={3}>
                        <Grid
                            sx={{ textAlign: 'center' }}
                            size={{
                                sm: 12,
                                md: 4,
                            }}
                        >
                            <CardMedia component="img" image={reservation.FleetCategory.product.image} alt={reservation.FleetCategory.Name} />
                            <Typography component="div" fontWeight="700" fontSize="18px" mt={1}>
                                {reservation.FleetCategory.Name}
                            </Typography>
                            <Typography variant="caption" color="text.secondary" fontSize="14px" component="div">
                                {reservation.FleetCategory.product.vehicleDescription}
                            </Typography>
                        </Grid>
                        <Grid
                            size={{
                                sm: 12,
                                md: 4,
                            }}
                        >
                            <Stack spacing={2}>
                                <div>
                                    <div className="fw-bold smaller text-muted">Pick up</div>
                                    <span className="fw-bold h4 m-0">{reservation.PickUpSite?.Name}</span>
                                    <br />
                                    <strong>{format(parseISO(reservation.PickUpDate), 'd MMM yyyy - h:mma')}</strong>
                                </div>
                                <div>
                                    <div className="fw-bold smaller text-muted">Drop off</div>
                                    <span className="fw-bold h4 m-0">{reservation.DropOffSite?.Name}</span>
                                    <br />
                                    <strong>{format(parseISO(reservation.DropOffDate), 'd MMM yyyy - h:mma')}</strong>
                                </div>
                                {reservation.InsuranceLine ? (
                                    <List
                                        subheader={
                                            <ListItem className="fw-bold smaller text-muted" disablePadding>
                                                Excess reduction
                                            </ListItem>
                                        }
                                    >
                                        <ListItem disablePadding>{reservation.InsuranceLine.Name}</ListItem>
                                    </List>
                                ) : null}
                                {extras.length ? (
                                    <List
                                        subheader={
                                            <ListItem className="fw-bold smaller text-muted" disablePadding>
                                                Extras
                                            </ListItem>
                                        }
                                    >
                                        {extras
                                            .filter(
                                                (e) =>
                                                    !(
                                                        e.Price?.Value < 0 &&
                                                        (e.Name.toLowerCase().includes('adjustment') || e.Name.toLowerCase().includes('discount') || e.Name.toLowerCase().includes('offset'))
                                                    )
                                            )
                                            .map((l) => (
                                                <ListItem disablePadding key={l.ProductCode || l.ProductId}>
                                                    {l.AllowMultiple ? `${l.Qty} x` : ''} {l.Name}
                                                </ListItem>
                                            ))}
                                    </List>
                                ) : null}
                            </Stack>
                        </Grid>
                        <Grid
                            size={{
                                sm: 12,
                                md: 4,
                            }}
                        >
                            <Stack spacing={2}>
                                <div>
                                    <div className="fw-bold smaller text-muted">Hirer name</div>
                                    <span className="fw-bold m-0">
                                        {reservation.HirerDetails.FirstName} {reservation.HirerDetails.LastName}
                                    </span>
                                </div>
                                {reservation.HirerDetails.Email ? (
                                    <div>
                                        <div className="fw-bold smaller text-muted">Hirer email</div>
                                        <span className="fw-bold m-0">{reservation.HirerDetails.Email}</span>
                                    </div>
                                ) : null}
                                {reservation.HirerDetails.MobileNumber ? (
                                    <div>
                                        <div className="fw-bold smaller text-muted">Hirer mobile no</div>
                                        <span className="fw-bold m-0">{reservation.HirerDetails.MobileNumber}</span>
                                    </div>
                                ) : null}
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid
                            size={{
                                sm: 12,
                            }}
                        >
                            <Typography gutterBottom variant="h5" component="div" fontWeight={700} textAlign={'right'}>
                                Product total {selectedCurrencyCode} <CurrencyFormatter price={reservation.TotalPrice} />
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </MuiCard>
            <CustomerVoucherModal reservation={reservation} />
        </>
    );
};

export default ReservationReviewCard;
