import { useCallback } from 'react';
import { fleetCategories } from '@jucy/rentals-common';
import { format } from 'date-fns';
import { useRentalsApi, useUpdateRouteParams } from '../../../hooks';
import { Deal } from '../../../lib/api/model/Deal';
import { setTime } from '../../../lib/dates';
import { queryStringToSearchFormValues } from '../../../lib/queryStringToSearchFormValues';
import availabilityStore from '../../../store/AvailabilityStore';
import dealStore from '../../../store/DealStore';
import FleetTypesStore from '../../../store/FleetTypesStore';
import rentalTripSearchStore from '../../../store/RentalTripSearchStore';
import sitesStore from '../../../store/SitesStore';
import { summaryFromTripDetails } from '../../../store/Summary/summaryFromTripDetails';
import tripStore, { RentalTripSearchParams } from '../../../store/TripStore';
import { BookingCart } from '../../../types/BookingCart';
import { UserMode } from '../../../types/UserMode';
import { useTripRouteParams } from './useTripRouteParams';

export const useInitStoreBySearchParams = () => {
    const { mode, fleetTypeOrResRef } = useTripRouteParams();
    const fleetTypeFromRoute = FleetTypesStore.getFleetTypeBySlug(fleetTypeOrResRef);
    const rentalsApiClient = useRentalsApi();
    const updateRoute = useUpdateRouteParams();
    return useCallback(
        async (params: RentalTripSearchParams) => {
            await tripStore.init({ state: 'loading', action: 'create' });
            const values = queryStringToSearchFormValues(params as unknown as Record<string, string>);
            const pickUpDateTime = values?.pickUpDate && values.pickUpTime ? setTime(values.pickUpDate, values.pickUpTime) : values?.pickUpDate;
            const dropOffDateTime = values?.dropOffDate && values.dropOffTime ? setTime(values.dropOffDate, values.dropOffTime) : values?.dropOffTime;
            const driverAge = params.driverAge ? Number(params.driverAge) : undefined;
            let fleetCategory = params.fleetCategoryCode ? fleetCategories.find((category) => category.code === params.fleetCategoryCode) : undefined;
            let fleetType = FleetTypesStore.getFleetTypeById(fleetCategory?.fleetTypeId) || fleetTypeFromRoute;
            let pickUpLocation = params.pickUpLocation ? sitesStore.getSiteByCode(params.pickUpLocation) : undefined;
            let dropOffLocation = params.dropOffLocation ? sitesStore.getSiteByCode(params.dropOffLocation) : undefined;
            let promoCode = params.campaignCode;
            let autoSelectFleetCat = false;
            let deal: Deal | undefined;

            if (params.dealId) {
                deal = await dealStore.fetchDeal(params.dealId).catch((e) => {
                    tripStore.state = 'error';
                    tripStore.error = e;
                    tripStore.errorTitle = 'Failed to load deal';
                    tripStore.message = 'Sorry we are unable to complete your request at this time\n' + 'Please email res@jucyworld.com to make a booking or check back later';
                    return undefined;
                });
                if (!deal) {
                    return;
                }
                if (deal?.fleetType) {
                    fleetType = deal.fleetType;
                }
                if (deal?.pickUpLocations?.length === 1) {
                    pickUpLocation = deal?.pickUpLocations[0];
                }
                if (deal?.dropOffLocations?.length === 1) {
                    dropOffLocation = deal?.dropOffLocations[0];
                }
                const dealFleetCatCode = deal.conditions?.productCodeOneOf?.[0];
                if (['deal', 'vehicle-relocation'].includes(deal.type) && deal.conditions.productCodeOneOf?.length === 1 && dealFleetCatCode) {
                    fleetCategory = fleetCategories.find((category) => category.code === dealFleetCatCode);
                    autoSelectFleetCat = true;
                }
                promoCode = deal?.code ? deal.code : '';
            }

            rentalTripSearchStore.init({
                action: 'create',
                fleetTypeSlug: fleetType?.slug,
                driverAge: params.driverAge,
                dropOffDate: dropOffDateTime,
                dropOffLocation: dropOffLocation || undefined,
                dropOffTime: dropOffDateTime,
                fleetType: fleetType || undefined,
                fleetCategory: fleetCategory?.code ? fleetCategory?.code : undefined,
                pickUpDate: pickUpDateTime,
                pickUpLocation: pickUpLocation || undefined,
                pickUpTime: pickUpDateTime,
                promoCode: promoCode || undefined,
                deal,
            });
            if (pickUpLocation && dropOffLocation && pickUpDateTime && dropOffDateTime && fleetCategory?.code) {
                const tripInfo = {
                    pickUpLocation: pickUpLocation.SiteCode,
                    dropOffLocation: dropOffLocation.SiteCode,
                    pickUpDate: format(pickUpDateTime, 'yyyy-MM-dd HH:mm'),
                    dropOffDate: format(dropOffDateTime, 'yyyy-MM-dd HH:mm'),
                    fleetCategory: fleetCategory.code,
                    driverAge: driverAge,
                    campaignCode: promoCode,
                };
                await Promise.all([
                    rentalsApiClient
                        .getTripDetails(tripInfo)
                        .then((tripDetails) => {
                            tripStore.summary = summaryFromTripDetails({
                                tripDetails: tripDetails,
                                tripInfo: tripInfo,
                                userMode: mode as UserMode,
                                action: 'create',
                                reservationReference: params.reservationReference,
                                reservationId: params.reservationId ? Number(params.reservationId) : undefined,
                            });

                            if (params.extras) {
                                for (const [code, qty] of Object.entries(params.extras)) {
                                    if (Number(qty) <= 0) {
                                        continue;
                                    }
                                    const excess = tripDetails?.insuranceProducts.items.find((i) => i.productCode === code);
                                    if (excess) {
                                        tripStore.summary.setSelectedInsurance(code);
                                        continue;
                                    }

                                    tripStore.summary.addSecondaryProduct(code, Number(qty));
                                }
                            }
                        })
                        .catch(),
                ]);
                const availabilityQuery = rentalTripSearchStore
                    .performSearch()
                    .then(() => availabilityStore.setSelectedFleetCategoryByCode(fleetCategory?.code))
                    .catch();
                if (autoSelectFleetCat && tripStore.summary?.fleetCategory) {
                    await availabilityQuery;
                    updateRoute(
                        {
                            activeTab: 'excess-reduction',
                        },
                        {
                            keepSearch: true,
                            search: {
                                fleetCategoryCode: tripStore.summary?.fleetCategory.code,
                            },
                        }
                    );
                }
            } else {
                tripStore.summary = new BookingCart({
                    userMode: mode as UserMode,
                    action: 'create',
                    pickUpLocation: pickUpLocation,
                    dropOffLocation: dropOffLocation,
                    pickUpDate: pickUpDateTime,
                    dropOffDate: dropOffDateTime,
                    driverAge: driverAge,
                    campaignCode: promoCode,
                    reservationReference: params.reservationReference,
                    reservationId: params.reservationId ? Number(params.reservationId) : undefined,
                });
                rentalTripSearchStore.performSearch().catch();
            }
            tripStore.state = 'done';
        },
        [mode, rentalsApiClient, updateRoute, fleetTypeFromRoute]
    );
};
