import { useGetUrlCountryCode } from '#/hooks/useGetUrlCountryCode';
import rentalTripSearchStore from '#/store/RentalTripSearchStore';
import { reaction } from 'mobx';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

export const CountryUrlSwitcher: React.FC = () => {
    const currentCode = useGetUrlCountryCode();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const disposer = reaction(
            () => rentalTripSearchStore.pickUpLocation,
            (currentSite, prevSite) => {
                if (!currentSite?.CountryCode || prevSite?.SiteCode === currentSite?.SiteCode) {
                    return;
                }
                if (currentCode !== currentSite.CountryCode) {
                    const currentPathname = location.pathname;
                    let newPathName = currentPathname.replace(`/${currentCode}/`, `/${currentSite.CountryCode}/`);
                    if (newPathName && !newPathName.startsWith(`/${currentSite.CountryCode}`)) {
                        newPathName = `/${currentSite.CountryCode}${newPathName}`;
                    }
                    if (newPathName !== currentPathname) {
                        navigate(`${newPathName}${location.search}`, { replace: true });
                    }
                }
            }
        );
        return () => disposer();
    }, [currentCode, location, navigate]);

    return null;
};
