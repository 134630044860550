import React from 'react';
import { CardContent, CardMedia, List, ListItem, Card as MuiCard, Stack, Typography, Grid2 as Grid } from '@mui/material';
import { format, parseISO } from 'date-fns';
import Quote from '../../lib/api/model/Quote';
import { Site } from '../../lib/api/model/Site';

const LocationAddress: React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & { location?: Site }> = ({ location, ...props }) => {
    if (location && location.Address1) {
        const name = location.Address1.Name || location.Name;
        const line1 = location.Address1.Line1 && location.Address1.Line1.trim();
        const line2 = location.Address1.Line2 && location.Address1.Line2.trim();
        const line3 = location.Address1.Line3 && location.Address1.Line3.trim();
        const state = location.Address1.State && location.Address1.State.trim();
        const city = location.Address1.City && location.Address1.City.trim();
        const postcode = location.Address1.Postcode && location.Address1.Postcode.trim();
        const country = location.Address1.Country && location.Address1.Country.trim();
        return (
            location &&
            location.Address1 && (
                <address {...props}>
                    <span className="h5">{name}</span>
                    <br />
                    {line1 && line1}
                    {line2 && (
                        <>
                            <br />
                            {line2}
                        </>
                    )}
                    {line3 && country !== 'New Zealand' && (
                        <>
                            <br />
                            {line3}
                        </>
                    )}
                    {state && state !== city && <>, {state}</>}
                    {city && <>, {city}</>}
                    {postcode && <>, {postcode}</>}
                    {country && (
                        <>
                            <br />
                            {country}
                        </>
                    )}
                </address>
            )
        );
    }
    return null;
};

const ReservationSummaryCard: React.FC<{ reservation: Quote }> = ({ reservation }) => {
    const extras = [
        ...reservation.MandatoryLines,
        ...(reservation.ExtrasLines || [])
            .slice()
            .filter((l) => !l.IsExcluded)
            .sort((p) => p.SortOrder),
    ];
    return (
        <MuiCard variant="outlined">
            <CardContent id="customer-voucher" sx={{ maxWidth: '100%', '&:last-child': { pb: 2 } }}>
                <Grid container spacing={3}>
                    <Grid size={12}>
                        <div className="fw-bold smaller h5">
                            Hirer name: {reservation.HirerDetails.FirstName} {reservation.HirerDetails.LastName}
                        </div>
                        <div className="fw-bold smaller">Reference number: {reservation.ReservationDisplayReference}</div>
                    </Grid>
                    <Grid
                        size={{
                            sm: 12,
                            md: 4,
                        }}
                    >
                        <Stack spacing={1}>
                            <div>
                                <div className="fw-bold smaller text-muted">Pick up</div>
                                <LocationAddress className="mb-2" location={reservation.PickUpSite} />
                                <strong>{format(parseISO(reservation.PickUpDate), 'd MMM yyyy - h:mmaaa')}</strong>
                            </div>
                            <div>
                                <div className="fw-bold smaller text-muted mt-4">Drop off</div>
                                <LocationAddress className="mb-2" location={reservation.DropOffSite} />
                                <strong>{format(parseISO(reservation.DropOffDate), 'dd MMM yyyy - h:mmaaa')}</strong>
                            </div>
                        </Stack>
                    </Grid>
                    <Grid
                        size={{
                            sm: 12,
                            md: 4,
                        }}
                    >
                        <Stack spacing={1}>
                            {reservation.InsuranceLine ? (
                                <List
                                    subheader={
                                        <ListItem className="fw-bold smaller text-muted" disablePadding>
                                            Excess reduction
                                        </ListItem>
                                    }
                                >
                                    <ListItem disablePadding>{reservation.InsuranceLine.Name}</ListItem>
                                </List>
                            ) : null}
                            {extras.length ? (
                                <List
                                    subheader={
                                        <ListItem className="fw-bold smaller text-muted" disablePadding>
                                            Extras
                                        </ListItem>
                                    }
                                >
                                    {extras.map((l) => (
                                        <ListItem disablePadding key={l.ProductCode || l.ProductId}>
                                            {l.AllowMultiple ? `${l.Qty} x` : ''} {l.Name}
                                        </ListItem>
                                    ))}
                                </List>
                            ) : null}
                        </Stack>
                    </Grid>
                    <Grid
                        sx={{ textAlign: 'center' }}
                        size={{
                            sm: 12,
                            md: 4,
                        }}
                    >
                        <CardMedia component="img" image={reservation.FleetCategory.product.image} alt={reservation.FleetCategory.Name} />
                        <Typography component="div" fontWeight="700" fontSize="18px" mt={1}>
                            {reservation.FleetCategory.Name}
                        </Typography>
                        <Typography variant="caption" color="text.secondary" fontSize="14px" component="div">
                            {reservation.FleetCategory.product.vehicleDescription}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </MuiCard>
    );
};
const CustomerReservationReviewCard: React.FC<{ reservation: Quote; editable?: boolean }> = ({ reservation }) => (
    <>
        <ReservationSummaryCard reservation={reservation} />
    </>
);

export default CustomerReservationReviewCard;
